import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/common/Layout";
import img1 from "../images/commercial/willow-square.jpeg";

import logo from "../images/logos/willow-square.png";
import FormCon from "../components/Form/FormCon";
import FieldArray from "../components/Form/FieldArray";
import LoadBtn from "../components/Form/LoadBtn";
import GalleryList from "../components/gallery/GalleryList";

const contactForm = {
  inputs: {
    type: {
      name: "type",
      type: "select",
      options: [
        {
          title: "Enquiry",
          value: "Enquiry"
        }
        // {
        //     title: "Careers",
        //     value: "Careers"
        // },
      ]
    },
    name: {
      name: "name",
      type: "text",
      placeholder: "Enter your name"
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Enter your email"
    },
    number: {
      name: "number",
      type: "tel",
      placeholder: "Enter your phone number"
    }
  },
  allIds: ["type", "name", "email", "number"],
  values: { type: "Enquiry" }
};

const Commercial = () => {
  const [tab, setTab] = useState(0);
  const data = useStaticQuery(graphql`
    {
      galleryJson {
        gallery {
          images {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
      }
    }
  `);

  useEffect(() => {
    setTab(0);
  }, []);

  return (
    <Layout>
      <section
        className="residence-banner d-flex align-items-center relative"
        style={{
          backgroundImage: `url(${img1})`
        }}
      >
        <div className="abs-full tint" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="banner-title">Willow Square</h2>
              <p className="banner-tagline">
                A vibrant workspace creates a productive environment.
              </p>
              {/* <button className="btn btn-lg btn-black">
                                Enquire Now
                            </button> */}
            </div>
          </div>
        </div>
      </section>
      <section className="residence-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 text-justify">
              <div className="pb-4">
                <img width="250" src={logo} alt="Willow Square" />
                <p className="residence-section-desc">
                  Baashyaam has also advanced into commercial development with
                  Willow Square coming up at Guindy, the new age IT/commercial
                  destination for businesses in Chennai. A boutique well-planned
                  property that is spread across 65000 sq. ft. in the Industrial
                  Estate, which is ideally connected to the airport, elite
                  residential hubs as well as other hotspots in the city.
                  Situated just half a kilometer from the Guindy metro station
                  and about six kilometers from the airport, Willow Square would
                  attract corporate brands, especially from the IT/ITeS, BFSI
                  and insurance sectors. The need for quality IT and ITES
                  projects has only increased with corporate occupier demand in
                  Guindy.
                </p>
                <p className="residence-section-desc">
                  Baashyaam will always endeavour to provide quality and
                  future-readiness in every aspect of this commercial real
                  estate development – right from sourcing the material, use of
                  technology, maintaining design aesthetics and adhering to
                  timelines, all the way to final delivery and after lease
                  services.
                </p>
              </div>
              <div className="pb-4">
                <h5 className="residence-section-title">Gallery</h5>
                <div className="gallery-tabs">
                  {data.galleryJson.gallery.map((item, idx) => (
                    <button
                      key={item.title}
                      onClick={() => setTab(idx)}
                      className={`btn ${tab === idx ? " active" : ""}`}
                    >
                      {item.title}
                    </button>
                  ))}
                </div>
                <GalleryList
                  images={(data.galleryJson.gallery[tab].images || []).map(
                    x => x.childImageSharp.fluid
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-3 text-center font-weight-bold">
                    Get In Touch
                  </h4>
                  <FormCon
                    resetOnSuccess
                    form={contactForm}
                    config={{
                      url: "bashyam/",
                      method: "POST"
                    }}
                    renderForm={({ fetching }) => (
                      <>
                        <FieldArray />
                        <div className="form-group">
                          <LoadBtn
                            fetching={fetching}
                            className="btn btn-block btn-logo-color"
                          >
                            Submit
                          </LoadBtn>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Commercial;
